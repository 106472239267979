<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />

    <v-card tile flat>
      <v-card-text>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-plus-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7" lg="7">
            <v-form @submit.prevent="submit">
              <v-select
                v-model="form.role"
                label="Role"
                dense
                outlined
                :items="roles"
                item-text="text"
                item-value="value"
                readonly
                :disabled="loading"
              ></v-select>
              <v-text-field
                v-model="form.name"
                label="Nama"
                placeholder="Nama"
                dense
                outlined
                autofocus
                :error-messages="errors.name"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                label="Email"
                placeholder="Email"
                dense
                outlined
                type="email"
                :error-messages="errors.email"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.username"
                label="Username"
                placeholder="Username"
                dense
                outlined
                :error-messages="errors.username"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.phone_number"
                label="No. Telp"
                placeholder="No. Telp"
                dense
                outlined
                :error-messages="errors.phone_number"
                :disabled="loading"
              ></v-text-field>
              <v-textarea
                v-model="form.address"
                label="Alamat"
                placeholder="Alamat"
                dense
                auto-grow
                outlined
                rows="4"
                :error-messages="errors.address"
                :disabled="loading"
              ></v-textarea>
              <div class="tw-text-right">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="5">
            <v-img
              v-if="$vuetify.breakpoint.mdAndUp"
              contain
              max-height="250"
              src="https://ik.imagekit.io/5zmdzllq3/undraw_Add_files_re_v09g_AXJ5tiXKN.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { post } from "@/api/developer";
import form from "@/mixins/form";
import page from "../mixins/page";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";

export default {
  components: { BreadcrumbComponent, LoadingOverlay },
  mixins: [form, page],
  data() {
    return {
      form: {
        addresss: "",
        name: "",
        phone_number: "",
        email: "",
        username: "",
        role: { value: 2, text: "Developer" },
      },
      roles: [{ value: 2, text: "Developer" }],
    };
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        await post(this.form);
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        this.$router.push({ name: "developer" });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
